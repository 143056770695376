import { axios } from '../utils/axios';
import {
  EnterpriseTotalDataType,
  UserListType,
  MemberDataInfoType,
  EnterpriseInfoType,
  InvitationRecordType,
  MemberInviteStatusType,
  OperatePlatformType,
  MemberPublishCountType,
  PublishVideoType,
  ContentListType,
  ContentDataType,
  ContentPlatformType,
  RepublishVideoType,
  VideoPlatformType,
  GlobalPassType,
  CreatorPassType,
  OverseaPlatformParams,
  PlatformIncreaseParams,
  PlatformIncreaseType,
  PlatformDataType,
  PlatformCodeParams,
  OperatePlatformParams,
} from '@/stores/MemberStore/types';

export interface IGetCreatorParams {
  limit?: number;
  offset?: number;
  orders?: string[];
  uids?: number[];
  nickname?: string;
  emails?: string[];
}

export interface IGetCreatorResponse {
  count: number;
  list: UserListType[];
}

export interface IGetEnterpriseMembersListParams {
  eid?: number;
  limit?: number;
  offset?: number;
  orders?: string[];
}

export interface IGetMembersDataListResponse {
  count: number;
  items: MemberDataInfoType[];
}

export interface ISendMemberInvitationParams {
  uid: number;
  status: ISendInvitationStatus;
}

export type ISendInvitationStatus =
  | 'INVITATION_STATUS_SENT' // 邀请已发送
  | 'INVITATION_STATUS_CANCELED'; // 邀请已取消

export interface IGetEnterpriseMemberListResponse {
  count: number;
  list: EnterpriseInfoType[];
}

export interface IGetInvitationRecordsParams {
  limit?: number;
  offset?: number;
  orders?: string[];
  uid?: number;
  eid?: number;
  inStatus?: MemberInviteStatusType[];
}

export interface IGetInvitationRecordsListResponse {
  count: number;
  list: InvitationRecordType[];
}

export interface IGetOperatePlatformResponse {
  list: OperatePlatformType[];
}

export interface IPublishCountResponse {
  list: MemberPublishCountType[];
}

export interface IContentListResponse {
  list: ContentListType[];
  nextCursor: number;
  total: number;
}

export interface IContentDataResponse {
  videoMetrics: {
    [key: number]: {
      sum: ContentDataType;
      platforms: ContentDataType[];
    };
  };
}

export interface IContentPlatformResponse {
  list: ContentPlatformType[];
  nextCursor: number;
}

export interface IVideoPlatformResponse {
  list: VideoPlatformType[];
  nextCursor: number;
}

export interface ICreatorPassResponse {
  foreignPlatform: CreatorPassType;
}

export interface ICreateAuthHistoryParams {
  uid: number;
  platformId: number;
  passportId?: number;
  use: string;
  authMode?: number;
  createAt?: string;
}

export interface IPlatformIncreaseDataResponse {
  items: PlatformIncreaseType[];
}

export interface IPlatformDataResponse {
  byPlatform: PlatformDataType[];
  incr: PlatformDataType;
  sum: PlatformDataType;
}

const memberServices = {
  // 达人基础信息列表
  getCreatorBaseInfoList: (params: IGetCreatorParams) =>
    axios.get<IGetCreatorResponse, IGetCreatorResponse>('/user/v2/users', {
      params,
    }),

  // 企业的统计数据
  getEnterpriseTotalData: () =>
    axios.get<EnterpriseTotalDataType, EnterpriseTotalDataType>('/spiderman/v1/enterprises/status'),

  // 获取企业成员数据列表 http://18.163.16.71:8800/v2/spiderman#tag/Enterprise/operation/Enterprise_ListMembers
  getMembersDataList: (params: IGetEnterpriseMembersListParams) =>
    axios.get<IGetMembersDataListResponse, IGetMembersDataListResponse>(
      '/spiderman/v1/enterprises/members',
      { params }
    ),

  // 发送邀请/取消邀请成员 http://18.163.16.71:8800/v2/user#tag/enterprise/operation/UserApi2_SendMemberOfInvitation
  sendMemberInvitation: (params: ISendMemberInvitationParams) =>
    axios.post('/user/v2/enterprise/member/invitation:send', params),

  // todo: 删除邀请接口 Allen暂时没有

  // 获取企业所属成员列表 http://18.163.16.71:8800/v2/user#tag/enterprise/operation/UserApi2_ListMembersOfEnterprise
  getEnterpriseMembersList: () =>
    axios.get<IGetEnterpriseMemberListResponse, IGetEnterpriseMemberListResponse>(
      '/user/v2/enterprise/members'
    ),

  // 获取企业成员邀请记录列表 http://18.163.16.71:8800/v2/user#tag/enterprise/operation/UserApi2_ListMemberActivityRecords
  getInvitationRecords: (params: IGetInvitationRecordsParams) =>
    axios.get<IGetInvitationRecordsListResponse, IGetInvitationRecordsListResponse>(
      '/user/v2/member/invitation_records',
      { params }
    ),

  /*
   * 成员内容管理
   */
  // 获取成员视频发布次数
  queryPublishCount: (uid: number, type: string, pidList?: number[]) =>
    axios.get<IPublishCountResponse, IPublishCountResponse>('/video/v1/worth', {
      params: { uid, type, pidList },
    }),
  // 发布视频
  publishVideo: (params: PublishVideoType) => axios.post('/video/v1/original', params),
  // 获取成员视频列表
  getContentList: (uid: number, cursor: number, size: number) =>
    axios.get<IContentListResponse, IContentListResponse>('/video/v1/original', {
      params: { uid, cursor, size },
    }),
  // 获取成员视频数据
  getContentData: (uid: number, vids: number[]) =>
    axios.get<IContentDataResponse, IContentDataResponse>('/spiderman/v2/videoMetrics', {
      params: { uid, vids },
    }),
  // 获取内容分发的平台列表
  getContentPlatformList: (uid: number, originalId: number, cursor: number) =>
    axios.get<IContentPlatformResponse, IContentPlatformResponse>('/video/v1/publish', {
      params: { uid, originalId, cursor },
    }),
  // 转发视频到其他平台
  republishVideo: (params: RepublishVideoType) => axios.post('/video/v1/publish', params),
  // 获取该视频分发到每个平台的视频信息
  getPlatformVideoInfoList: (uid: number, originalId: number, cursor: number) =>
    axios.get<IVideoPlatformResponse, IVideoPlatformResponse>('/video/v1/video', {
      params: { uid, originalId, cursor },
    }),
  // 获取该平台所有的视频
  getVideosOfPlatform: (uid: number, platformId: number, cursor: number, size: number) =>
    axios.get<IVideoPlatformResponse, IVideoPlatformResponse>(
      `/video/v1/video/platform/${platformId}`,
      {
        params: { uid, cursor, size },
      }
    ),
  // 删除某个视频
  deletePlatformVideo: (id: number, uid: number) =>
    axios.delete(`/video/v1/publish/${id}`, { params: { uid } }),

  /*
   * Global Pass
   */
  // 获取Global Pass信息
  getGlobalPass: (uid: number) =>
    axios.get<GlobalPassType, GlobalPassType>('/user/v2/passport', { params: { uid } }),
  // 提交Global Pass
  submitGlobalPass: (params: GlobalPassType) => axios.post('/user/v2/passport', params),
  // 获取Creator Pass信息
  getCreatorPass: (uid: number) =>
    axios.get<ICreatorPassResponse, ICreatorPassResponse>('/user/v2/foreign_platform_ai', {
      params: { uid },
    }),
  // 提交Creator Pass
  submitCreatorPass: (params: CreatorPassType) => axios.put('/user/v2/foreign_platform', params),
  //创建认证历史
  createAuthHistory: (params: ICreateAuthHistoryParams) =>
    axios.post('/user/v2/auth_history', params),
  // 创建平台资料
  createOverseaPlatform: (params: OverseaPlatformParams) =>
    axios.post('/user/v2/foreign_platform', params),

  /*
   * 成员平台管理
   */
  // 查询成员运营中的平台
  getMemberPlatforms: (uid: number) =>
    axios.get<IGetOperatePlatformResponse, IGetOperatePlatformResponse>(
      '/user/v2/operating_platforms',
      { params: { uid: uid } }
    ),
  // 获取成员平台增量数据 http://18.163.16.71:8800/v2/spiderman#tag/Spiderman/operation/Spiderman_ListTotalIncrs
  getPlatformIncreaseData: (params: PlatformIncreaseParams) =>
    axios.get<IPlatformIncreaseDataResponse, IPlatformIncreaseDataResponse>(
      '/spiderman/v1/creators/totals/incr',
      {
        params,
      }
    ),
  // 获取单个成员的平台数据 http://18.163.16.71:8800/v2/spiderman#tag/Spiderman/operation/Spiderman_GetCreatorTotalStatus
  getPlatformData: (creatorId?: number) =>
    axios.get<IPlatformDataResponse, IPlatformDataResponse>(
      '/spiderman/v1/creators/totals/status',
      { params: { creatorId: creatorId } }
    ),

  // 创建运营平台
  createOperatePlatform: (params: OperatePlatformParams) =>
    axios.post('/user/v2/operating_platform', params),
  // 进入到提交验证码页面,准备输入验证码
  readyToGetCode: (params: PlatformCodeParams) =>
    axios.post('/user/v2/account/verify_code:online', params),
  // 发送/提交验证码 (不传code时为发送, 传code时为提交)
  submitPlatformCode: (params: PlatformCodeParams) =>
    axios.post('/user/v2/account/verify_code:send', params),
  getMemberPlatformCode: (uid: number, pid: number, sessionType: string) =>
    axios.get<PlatformCodeParams, PlatformCodeParams>(
      `/user/v2/account/verify_code?uid=${uid}&pid=${pid}&sessionType=${sessionType}`
    ),
};

export default memberServices;
