import axios from 'axios';
import { message } from 'antd';
import Cookie from 'js-cookie';
import CONFIG from '../config';
import { tf } from '@/i18n';

const authInfo = {
  onLogout: () => undefined,
  showMessage: false,
};

function setAuthInfo(onLogout: () => any) {
  authInfo.onLogout = onLogout;
}

const a = axios.create({
  baseURL: CONFIG.BASEURL,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 30000,
});

a.interceptors.request.use(
  (config) => {
    // http header都加上token
    const token = Cookie.get('enterprise_token');
    config.headers = Object.assign({}, config.headers, {
      Authorization: `Bearer ${token}`,
    });
    return config;
  },
  (error) => Promise.reject(error)
);

a.interceptors.response.use(
  (response) => {
    // console.log('axios response:', response);
    if (response.status === 200 || (response.status >= 200 && response.status < 400)) {
      return getResult(response);
    } else {
      const errorObj = getErrorObject(response);
      showErrorMessage(errorObj);
      reactOnStatusCode(errorObj);
      return Promise.reject(response.data);
    }
  },
  (error) => {
    const errorObj = getErrorObject(error.response);
    showErrorMessage(errorObj);

    const timer = setTimeout(() => {
      timer && clearTimeout(timer);
      reactOnStatusCode(errorObj);
    }, 1000);

    return Promise.reject(errorObj.data);
  }
);

const a1 = axios.create({
  baseURL: CONFIG.BASEURL,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
  responseType: 'blob',
  timeout: 30000,
});

a1.interceptors.request.use(
  (config) => {
    // http header都加上token
    const token = Cookie.get('enterprise_token');
    config.headers = Object.assign({}, config.headers, {
      Authorization: `Bearer ${token}`,
    });
    return config;
  },
  (error) => Promise.reject(error)
);

a1.interceptors.response.use(
  (response) => {
    // console.log('axios response:', response);
    if (response.status === 200 || (response.status >= 200 && response.status < 400)) {
      return getResult(response);
    } else {
      const errorObj = getErrorObject(response);
      showErrorMessage(errorObj);
      reactOnStatusCode(errorObj);
      return Promise.reject(response.data);
    }
  },
  (error) => {
    const errorObj = getErrorObject(error.response);
    showErrorMessage(errorObj);

    const timer = setTimeout(() => {
      timer && clearTimeout(timer);
      reactOnStatusCode(errorObj);
    }, 1000);

    return Promise.reject(errorObj.data);
  }
);

/* 无需token的请求 */
const a2 = axios.create({
  baseURL: CONFIG.BASEURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

a2.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

a2.interceptors.response.use(
  (response) => {
    // console.log('axios response:', response);
    if (response.data || (response.status >= 200 && response.status < 400)) {
      return getResult(response);
    } else {
      const errorObj = getErrorObject(response);
      showErrorMessage(errorObj);
      reactOnStatusCode(errorObj);
      return Promise.reject(response.data);
    }
  },
  (error) => {
    const errorObj = getErrorObject(error.response);
    showErrorMessage(errorObj);
    setTimeout(() => {
      reactOnStatusCode(errorObj);
    }, 5000);
    return Promise.reject(errorObj.data);
  }
);

function getErrorObject(response: any) {
  // No response data, probably no network or 500
  console.log('error ', response?.data ? JSON.stringify(response.data) : response);

  let errorReason = '';
  let errorMessage = 'Server Error,  Can not Access To The Server';
  if (response && response.data) {
    errorReason = response.data.reason || '';
    errorMessage = response.data.message || 'Server Error,  Can not Access To The Server';
  }

  return {
    ...response,
    reason: errorReason,
    message: errorMessage,
    code: response?.status || -1,
    status: response?.status || -1,
  };
}

function reactOnStatusCode(error: any) {
  if (error?.data?.reason === 'USER_NOT_FOUND') {
    // 查不到该用户 (后台清楚账户后, 重新登录)
    authInfo.onLogout();
    return;
  }
  if (error) {
    switch (error.status) {
      case 401:
        // 登录状态失效
        authInfo.onLogout();
        break;
      // case 403:
      // 无权限
      // authInfo.onLogout();
      // break;
      default:
        break;
    }
  }
}

function showErrorMessage(error: any) {
  if (error) {
    switch (error.status) {
      case 401:
        if (!authInfo.showMessage && window.location.pathname !== '/signin') {
          authInfo.showMessage = true;
          message.warning(tf('global.message_no_token'), 2, () => {
            authInfo.showMessage = false;
          });
        }
        break;
      case 403:
        // message.warning(tf('global.message_no_permission'));
        break;
      default:
        console.log(error.message);
        break;
    }
  }
}

function getResult(response: any) {
  const errorMessage = { message: 'Server Error,  Can not Access To The Server' };

  if (response && response.status >= 200 && response.status < 400) {
    return response.data || '';
  }

  throw errorMessage;
}

export { a as axios, a2 as axios2, a1 as axios1, setAuthInfo };
